* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;

}

.cagelogin {
    margin: 0px auto;
    padding: 80px;
}

.field {
    max-width: 400px;
    width: 400px;
    text-align: center;
}

.form {
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
